export const GET_CURRENCIES_REQUEST = "GET_CURRENCIES_REQUEST";
export const GET_CURRENCIES_SUCCESS = "GET_CURRENCIES_SUCCESS";

export const SHOW_CURRENCY_REQUEST = "SHOW_CURRENCY_REQUEST";
export const SHOW_CURRENCY_SUCCESS = "SHOW_CURRENCY_SUCCESS";

export const POST_CURRENCY_REQUEST = "POST_CURRENCY_REQUEST";
export const POST_CURRENCY_SUCCESS = "POST_CURRENCY_SUCCESS";

export const PUT_CURRENCY_REQUEST = "PUT_CURRENCY_REQUEST";
export const PUT_CURRENCY_SUCCESS = "PUT_CURRENCY_SUCCESS";

export const DELETE_CURRENCY_REQUEST = "DELETE_CURRENCY_REQUEST";
export const DELETE_CURRENCY_SUCCESS = "DELETE_CURRENCY_SUCCESS";
