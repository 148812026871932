const options = [
  {
    key: "users",
    leftIcon: "ion-android-contacts",
    eventKey: "sidebar.users",
    label: "sidebar.users",
  },

  {
    key: "categories",
    label: "sidebar.categories",
    eventKey: "sidebar.categories",
    leftIcon: "ion-ios-pint-outline",
    children: [
      {
        key: "categories",
        label: "sidebar.categories",
        eventKey: "sidebar.categories",
        leftIcon: "ion-ios-pint-outline",
      },
      {
        key: "specific-types",
        label: "sidebar.specificTypes",
        eventKey: "sidebar.specificTypes",
        leftIcon: "ion-ios-pint-outline",
      },
      {
        key: "locations",
        label: "sidebar.locations",
        eventKey: "sidebar.locations",
        leftIcon: "ion-ios-pint-outline",
      },
    ],
  },
  {
    key: "filters",
    label: "sidebar.filters",
    eventKey: "sidebar.filters",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "packages-list",
    label: "sidebar.packages",
    leftIcon: "ion-ios-pint-outline",
    children: [
      {
        key: "packages",
        label: "sidebar.packages",
      },

      {
        key: "packages-options",
        label: "sidebar.packagesoptions",
      },
    ],
  },
  {
    key: "localities",
    label: "sidebar.localities",
    eventKey: "sidebar.localities",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "streets",
    label: "sidebar.streets",
    eventKey: "sidebar.streets",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "partners",
    label: "sidebar.partners",
    eventKey: "sidebar.partners",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "languages",
    label: "sidebar.languages",
    eventKey: "sidebar.languages",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "specializations",
    label: "sidebar.specializations",
    eventKey: "sidebar.specializations",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "features",
    label: "sidebar.features",
    eventKey: "sidebar.features",
    leftIcon: "ion-ios-pint-outline",
  },
  {
    key: "currencies",
    label: "sidebar.currencies",
    eventKey: "sidebar.currencies",
    leftIcon: "ion-cash",
  },
];
export default options;
