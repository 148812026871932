import App from "@iso/redux/app/reducer";
import mediaReducer from "@iso/redux/medias/reducer";
import filtersCategories from "@iso/redux/filtersCategories/reducer";
import filtersReducer from "@iso/redux/filters/reducer";
import locationsReducer from "@iso/redux/locations/reducer";
import localitiesReducer from "@iso/redux/localities/reducer";
import packagesReducer from "@iso/redux/packages/reducer";
import packagesoptionsReducer from "@iso/redux/packagesOptions/reducer";
import specificTypesReducer from "@iso/redux/specificTypes/reducer";
import categoriesReducer from "@iso/redux/categories/reducer";
import themeReducer from "@iso/redux/themeSwitcher/reducer";
import usersReducer from "@iso/redux/users/reducer";
import permissionsReducer from "@iso/redux/permissions/reducer";
import streetsReducer from "@iso/redux/streets/reducer";
import partnersReducer from "@iso/redux/partners/reducer";
import languagesReducer from "@iso/redux/languages/reducer";
import specializationsReducer from "@iso/redux/specializations/reducer";
import featuresReducer from "@iso/redux/features/reducer";
import currenciesReducer from "@iso/redux/currencies/reducer";

import { combineReducers } from "redux";

const reducers = combineReducers({
  Auth: usersReducer,
  ThemeSwitcher: themeReducer,
  MediaReducer: mediaReducer,
  CategoriesReducer: categoriesReducer,
  FiltersCategories: filtersCategories,
  FiltersReducer: filtersReducer,
  LocationsReducer: locationsReducer,
  LocalitiesReducer: localitiesReducer,
  StreetsReducer: streetsReducer,
  PartnersReducer: partnersReducer,
  LanguagesReducer: languagesReducer,
  PackagesReducer: packagesReducer,
  PackagesoptionsReducer: packagesoptionsReducer,
  SpecificTypesReducer: specificTypesReducer,
  PermissionsReducer: permissionsReducer,
  SpecializationsReducer: specializationsReducer,
  FeaturesReducer: featuresReducer,
  CurrenciesReducer: currenciesReducer,
  App: App,
});

export default reducers;
